import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const hostURL =
  "https://nftbazar-backend-main-104732163593.europe-west9.run.app/";
const artistURL = `${hostURL}artist/file/`;
const previewURL = `${hostURL}nft/prevfile/`;
const originalURL = `${hostURL}nft/file/`;

const formatName = (name) => {
  return name.replace(/ /g, "_").toLowerCase();
};

const parseName = (name) => {
  return name ? name.replace(/_/g, " ").toLowerCase() : "";
};

const useToGetUserData = () => {
  const [userData, setUserData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const apiURL = `${hostURL}user`;
      try {
        const response = await axios.get(apiURL, {
          withCredentials: true,
        });
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return userData;
};

const useToGetString = (element, link) => {
  const [fetchStroke, setFetchStroke] = useState(null);

  useEffect(() => {
    if (!element || !link) return;

    const getStrokes = async () => {
      try {
        const fetchPromises = Array.isArray(element)
          ? element.map((id) =>
              axios
                .get(`${hostURL}${link}/${id}`)
                .then((response) => response.data)
            )
          : [
              axios
                .get(`${hostURL}${link}/${element}`)
                .then((response) => response.data),
            ];

        const results = await Promise.all(fetchPromises);
        setFetchStroke(Array.isArray(element) ? results : results[0]);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    getStrokes();
  }, [element, link]);

  return fetchStroke;
};

const useToGetItemData = (id, link, count = 10, startId = 1) => {
  const [artistItem, setArtistItem] = useState(null);

  useEffect(() => {
    if (!id || !link) return;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${hostURL}${link}/${id}?count=${count}&start_id=${startId}`
        );
        setArtistItem(response.data);
      } catch (error) {
        // console.error("Error fetching item data:", error);
      }
    };

    fetchData();
  }, [id, link, count, startId]);

  return { artistItem };
};

const useToGetData = (
  route,
  count = 10,
  offset = 0,
  delay = 0,
  filters = {}
) => {
  const [blockItems, setBlockItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!route) return;

    const fetchData = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, delay));

      let apiURL = `${hostURL}${route}?count=${count}&offset=${offset}`;

      if (filters.price && filters.price !== "All") {
        let priceValue = filters.price;

        if (priceValue === "Under 1K USD") {
          priceValue = 1000;
        } else if (priceValue === "Under 5K USD") {
          priceValue = 5000;
        } else if (priceValue === "Under 10K USD") {
          priceValue = 10000;
        }

        apiURL += `&price=${encodeURIComponent(priceValue)}`;
      }

      Object.entries(filters).forEach(([key, value]) => {
        if (value && value !== "All" && value !== "" && key !== "price") {
          // استبعاد فلتر السعر من هنا
          apiURL += `&${key}=${encodeURIComponent(value)}`;
        }
      });

      try {
        const response = await axios.get(apiURL);
        setBlockItems(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [route, count, offset, delay, filters]);

  return { blockItems, loading };
};

const useToFindItem = (type, itemID) => {
  const [itemData, setItemData] = useState();
  useEffect(() => {
    if (!itemID) return;
    const fetchData = async () => {
      const apiURL = `${hostURL}${type}/${itemID}`;
      try {
        const response = await axios.get(apiURL);
        setItemData(response.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchData();
  }, [type, itemID]);
  return itemData;
};

const useToGetCollection = (type, owner_id) => {
  const [dataArray, setDataArray] = useState();
  useEffect(() => {
    if (!owner_id) return;
    const fetchData = async () => {
      const apiURL = `${hostURL}${type}?artist_id=${owner_id}`;
      try {
        const response = await axios.get(apiURL);
        setDataArray(response.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchData();
  }, [owner_id]);

  return dataArray;
};

const useToList = (route, type, count = 10, startId = 0) => {
  const { blockItems } = useToGetData(route, count, startId);
  const [fetchList, setFetchList] = useState([]);

  useEffect(() => {
    if (!blockItems || !type) return;

    const typeList = blockItems.flatMap((item) =>
      Array.isArray(item[type]) ? item[type] : [item[type]]
    );

    const uniqueList = Array.from(
      new Set(typeList.map((item) => item.trim().toLowerCase()))
    );

    setFetchList(uniqueList);
  }, [type, blockItems]);

  return fetchList;
};

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return () => clearTimeout(timer);
  }, [location]);

  return null;
};

export const registerUser = async (userData) => {
  const url = `${hostURL}user/register`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();

    if (!response.ok) {
      let errorMessage = "";

      if (data.detail && typeof data.detail === "string") {
        errorMessage = data.detail;
      } else if (
        data.detail &&
        Array.isArray(data.detail) &&
        data.detail.length > 0
      ) {
        const errorDetail = data.detail[0];
        errorMessage = errorDetail.msg || "Registration failed";
      } else {
        errorMessage = "Registration failed";
      }

      throw new Error(errorMessage);
    }

    return data;
  } catch (error) {
    console.error("Error during registration:", error.message);
    throw error;
  }
};

export const postCheckCode = async (code, userEmail) => {
  const url = new URL(`${hostURL}user/check_code`);
  url.searchParams.append("code", code);
  url.searchParams.append("user_email", userEmail);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, errorData };
    }
  } catch (error) {
    console.error("Error during check code:", error);
    return { success: false, error: "Failed to connect to the server." };
  }
};

export const postLogin = async (email, password) => {
  try {
    const response = await fetch(`${hostURL}user/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      let errorMessage = "";

      if (errorData.detail && typeof errorData.detail === "string") {
        errorMessage = errorData.detail;
      } else if (
        errorData.detail &&
        Array.isArray(errorData.detail) &&
        errorData.detail.length > 0
      ) {
        const errorDetail = errorData.detail[0];
        errorMessage = errorDetail.msg || "Invalid email or password.";
      } else {
        errorMessage = errorData.message || "Something went wrong.";
      }

      return { success: false, errorMessage };
    }

    return { success: true };
  } catch (error) {
    console.error("Error during login:", error);
    return { success: false, errorMessage: "Failed to connect to the server." };
  }
};

export const verifyLogin = async () => {
  try {
    const verifyResponse = await fetch(`${hostURL}user`, {
      method: "GET",
      credentials: "include",
    });

    if (verifyResponse.ok) {
      return { success: true };
    } else {
      return {
        success: false,
        errorMessage: "Unauthorized access. Please log in again.",
      };
    }
  } catch (error) {
    console.error("Error during login verification:", error);
    return { success: false, errorMessage: "Failed to connect to the server." };
  }
};

export const postResendCode = async (email) => {
  try {
    const url = new URL(`${hostURL}user/resend_code`);
    url.searchParams.append("email", email);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      let errorMessage = "Failed to resend code.";

      if (errorData.detail && Array.isArray(errorData.detail)) {
        errorMessage = errorData.detail
          .map((error, index) => `${index + 1}: ${error.msg}`)
          .join("\n");
      } else if (errorData.detail && typeof errorData.detail === "string") {
        errorMessage = errorData.detail;
      }

      return { success: false, errorMessage };
    }
  } catch (error) {
    console.error("Error during resend code:", error);
    return { success: false, errorMessage: "Failed to connect to the server." };
  }
};

const toTitleCase = (str) => {
  if (!str){
    return;
  }
  return str
    .toLowerCase() 
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};


export {
  toTitleCase,
  formatName,
  useToGetItemData,
  useToGetData,
  useToFindItem,
  useToList,
  ScrollToTop,
  useToGetString,
  useToGetUserData,
  useToGetCollection,
  hostURL,
  artistURL,
  previewURL,
  originalURL,
};
