import React, { useState, useEffect } from 'react';
import { legacyText } from '../../legacy';
import { useParams, Link, useNavigate } from 'react-router-dom';
import "./Legal.scss";
// import cover_01 from "../../assets/background/image_asset_02.png";
// import cover_01 from "../../assets/background/img.jpg";


const Legal = () => {
  const { section } = useParams();
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);


  const currentSection = legacyText.find(item => item.source === section) || legacyText[0];


  useEffect(() => {
    if (!section || !legacyText.some(item => item.source === section)) {
      navigate(`/legal/${legacyText[0].source}`, { replace: true });
    }
  }, [section, navigate]);


  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className='legal'>
      <div className='lgl-cover' /*style={{ backgroundImage: `url(${cover_01})` }}*/>
        <div className='lgl-header'>{currentSection.name}</div>
      </div>

      <div className="legal-menu">
        <button className="dropdown-toggle" onClick={handleDropdownToggle}>
          <p className={`drp-bullet ${isDropdownOpen ? 'open' : ''}`}>&lt;</p>{currentSection.name}
        </button>

        {isDropdownOpen && (
          <div className="dropdown-menu">
            {legacyText.map((item, index) => (
              <Link key={index} to={`/legal/${item.source}`} onClick={() => setDropdownOpen(false)}>
                {item.name}
              </Link>
            ))}
          </div>
        )}


        <div className="legal-links">
          {legacyText.map((item, index) => (
            <Link key={index} to={`/legal/${item.source}`}>
              {item.name}
            </Link>
          ))}
        </div>
      </div>

      <div className="legal-content">
        {currentSection.text}
      </div>
    </div>
  );
};

export default Legal;
